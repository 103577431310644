const competencesSections = document.querySelectorAll(".competences");

if (competencesSections && competencesSections.length > 0) {
    competencesSections.forEach(section => {
        const button = section.querySelector(".js-show-values");
        const list = section.querySelector(".competences__list");
        button.addEventListener("click", () => {
            button.classList.add("-hide");
            list.classList.add("-show-mobile");
        })
    });
}